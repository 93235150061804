import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import List from '@mui/material/List';

// Components
import AgendaHeader from './header';
import AgendaForm from './item';
import AgendaJornada from './journey/container';
import Modal from './modals/index';

// Util
import useOpen from '../../../../components/hooks/useOpen';
import ToolTip from '../../../../components/util/tooltip';
import Axios from '../../../../config/axiosv2';
import handler from '../../../../redux/actions/handler';
import useAgendasJornada from '../../../../swr/hooks/useAgendasJornada';

const Agendas = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { data: agendas, mutate } = useAgendasJornada();
    const [select, setSelect] = useState(null);

    const modal = useOpen();

    const onSelect = (agenda) => setSelect(agenda);
    const onSelectBack = () => {
        mutate();
        setSelect(null);
    };
    const onVisible = async (agenda) => {
        try {
            setIsLoading(true)
            await Axios.put(`/agendas/visible/${agenda.idAgenda}`)
            mutate();
        } catch (e) {
            handler(dispatch)
        } finally {
            setIsLoading(false)
        }
    };

    return select ? (
      <AgendaJornada
        item={select}
        loading={isLoading}
        onSelectBack={onSelectBack}
      />
    ) : (
      <div className="flex grow column">
        <AgendaHeader
          select={select}
          onCreate={modal.handleOpen}
          onSelectBack={onSelectBack}
        />
        <List component="ul">
          {agendas.map((item) => (
            <AgendaForm
              key={item.idAgenda}
              item={item}
              onSelect={onSelect}
              onVisible={onVisible}
            />
          ))}
        </List>
        <ToolTip
          handleOpen={modal.handleOpen}
          title="Nueva agenda"
          color="primary"
        />
        <Modal item={select} open={modal.open} onClose={modal.handleClose} />
      </div>
    );
}

export default Agendas;